import React, { useEffect } from "react";
import { connect } from "react-redux";
import { isValidObject } from "../../Services/validators";
import Petitions from "../../Components/Petitions/Petitions";
import ConditionalRender from "../../Components/ConditionalRender/ConditionalRender";
import Volunteer from "../../Components/Volunteer/Volunteer";
import Jobs from "../../Components/Jobs/Jobs";
// import Announcement from "../../Components/Announcement/Announcement";
import Header from "../../Components/Header/Header";
import { setDisplayScreen } from "../../Redux/navigation/action";
import "./Home.css";
import { updateOpting } from "../../Redux/volunteer/action";
import engImage from "../../Assets/jobFairEng.webp";
import tamImage from "../../Assets/jobFairTam.webp";
import { downloadFile, rippleEffect } from "../../Utils/constants";
import { setErrorStatus } from "../../Redux/status/action";
import { useState } from "react";
import { uploadResume } from "../../Redux/profile/action";
import { Route, Routes } from "react-router-dom";
import SuccessModal from "../../Components/SuccessModal/SuccessModal";

function Home(props) {
  const [file, setFile] = useState({
    document: [],
  });

  useEffect(() => {
    if (
      !isValidObject(props.profile.data) ||
      props.auth.register.transactionId
    ) {
      props.navigate("/register");
    }
    // eslint-disable-next-line
  }, [props.profile.data, props.auth.register]);

  useEffect(() => {
    if (file.document && file.document.length > 0) {
      props.uploadResume(
        props.profile.data.documentId,
        file.document[0],
        props.navigate
      );
    }
    // eslint-disable-next-line
  }, [file]);

  return (
    <>
      <Header
        languageData={props.languageData}
        setDisplayScreen={(screen) => {
          props.setDisplayScreen(screen);
        }}
        navigation={props.navigation}
        profileOnClick={() => {
          props.navigate("/profile");
        }}
      />
      <div
        style={{ height: "calc(100% - 64px)" }}
        className={` ${
          props.navigation.screen === "jobs" ? "background-white-half" : ""
        } flex-justify-content-space-between flex-direction-column flex-align-items-center`}
      >
        <div className="inherit-parent-height padding-large max-width-500px inherit-parent-width" style={props.navigation.screen === "home" ? {paddingBottom: "0px"} : {}}>
          <ConditionalRender
            condition={props.navigation.screen === "petitions"}
          >
            <Petitions
              petitions={props.petitions}
              languageData={props.languageData}
              petitionOnclick={(documentId) => {
                props.navigate(`/petitions/${documentId}`);
              }}
              newPetitionOnclick={() => {
                props.navigate("/createPetition");
              }}
            />
          </ConditionalRender>

          <ConditionalRender condition={props.navigation.screen === "jobs"}>
            <Jobs
              languageData={props.languageData}
              profile={props.profile}
              jobs={props.jobs}
              navigate={props.navigate}
            />
          </ConditionalRender>

          <ConditionalRender
            condition={props.navigation.screen === "volunteering"}
          >
            <Volunteer
              languageData={props.languageData}
              profile={props.profile}
              volunteer={props.volunteer}
              navigate={props.navigate}
              updateOpting={props.updateOpting}
            />
          </ConditionalRender>

          <ConditionalRender condition={props.navigation.screen === "home"}>
            {/* <Announcement
              languageData={props.languageData}
              language={props.profile.language}
              announcements={props.announcements}
              navigate={props.navigate}
            /> */}

            <div className="inherit-parent-height inherit-parent-width flex-align-items-center position-relative">
              <img
                className="inherit-parent-width"
                style={{
                  height: "calc(100% - 64px)",
                  objectFit: "contain",
                }}
                src={props.profile.language === "english" ? engImage : tamImage}
                alt="job-fair-banner"
              />
              <div 
                className="flex-justify-content-center inherit-parent-width"
                style={{
                  position: "absolute",
                  bottom: 0,
                  maxWidth: "500px",
                }}
              >
                <div
                  className="inherit-parent-width"
                >
                  {props.profile.data?.resumeUrl ? (
                    <div
                      style={{
                        boxShadow: "0px 0px 6px 6px white",
                      }}
                    >
                      <div
                        style={{
                          height: "40px",
                          borderRadius: "2px",
                          border: "solid 1px #0000003D",
                        }}
                        className="background-color-white  flex-justify-content-center flex-align-items-center font-size-small"
                      >
                        {props.languageData.alreadyApplied}
                      </div>
                      <div
                        onClick={() => {
                          if (props.profile.data?.resumeDownloadURL) {
                            downloadFile(props.profile.data?.resumeDownloadURL);
                          }
                        }}
                        style={{
                          fontSize: "12px",
                        }}
                        className="text-align-center background-color-white padding-top-large padding-bottom-large"
                      >
                        <span
                          className={`${
                            props.profile.language === "english"
                              ? "font-color-primary cursor-pointer"
                              : ""
                          }`}
                        >
                          {props.profile.language === "english"
                            ? "Click here"
                            : "பதிவேற்றப்பட்ட தன்விவரக்குறிப்பை காண"}
                        </span>{" "}
                        <span
                          className={`${
                            props.profile.language === "tamil"
                              ? "font-color-primary cursor-pointer"
                              : ""
                          }`}
                        >
                          {props.profile.language === "english"
                            ? "to view your uploaded resume"
                            : "இங்கே கிளிக் செய்யவும்"}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <>
                      <form
                        className="inherit-parent-width"
                        onChange={(event) => {
                          if (event.target.files) {
                            if (
                              typeof event.target.files[0]?.size === "number" &&
                              event.target.files[0].size > 5000000
                            ) {
                              return props.setErrorStatus({
                                code: "input/file-size-exceeded",
                                message: {
                                  english:
                                    "Document size should be less than 5MB",
                                  tamil:
                                    "Document size should be less than 5MB",
                                },
                              });
                            }
                            if (
                              [
                                "application/pdf",
                                "application/msword",
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                              ].includes(event.target.files[0]?.type) === true
                            ) {
                              setFile({
                                file,
                                document: [event.target.files[0]],
                              });
                            } else {
                              return props.setErrorStatus({
                                code: "input/invalid-file",
                              });
                            }
                          }
                        }}
                      >
                        <label>
                          <input
                            type="file"
                            accept=".pdf,.doc,.docx"
                            name={
                              props.inputName
                                ? props.inputName
                                : "fileUploaderInput"
                            }
                            onClick={(event) => {
                              event.target.value = "";
                            }}
                            className="visibility-hidden position-absolute"
                          />
                          <div
                            onClick={(event) => {
                              rippleEffect(event);
                            }}
                            style={{
                              ...(props.profile.loading
                                ? { background: "#b2bff7" }
                                : { background: "#4362ea" }),
                              height: "40px",
                              borderRadius: "2px",
                              boxShadow: "0px 0px 4px 16px white",
                            }}
                            className="cursor-pointer  margin-bottom-medium font-color-white flex-justify-content-center flex-align-items-center font-size-medium"
                          >
                            {props.profile.loading ? (
                              <>
                                <div className={`${"dot-pulse-dark"}`} />
                              </>
                            ) : (
                              <>
                                <span
                                  style={{
                                    fontSize: "28px",
                                    marginRight: "4px",
                                  }}
                                >
                                  +
                                </span>{" "}
                                {props.languageData.uploadResume}
                              </>
                            )}
                          </div>
                        </label>
                      </form>

                      <div
                        style={{
                          height: "40px",
                          borderRadius: "2px",
                          boxShadow: "0px 0px 6px 6px white",
                        }}
                        className="background-color-white margin-bottom-default flex-justify-content-center flex-align-items-center font-size-small"
                      >
                        {props.languageData.supportedFormatResumeDescription}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <Routes>
              <Route
                path="success"
                element={
                  <SuccessModal
                    showIcon={true}
                    languageData={props.languageData}
                    navigate={props.navigate}
                  />
                }
              />
            </Routes>
          </ConditionalRender>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    announcements: state.announcements,
    profile: state.profile,
    petitions: state.petitions,
    navigation: state.navigation,
    volunteer: state.volunteer,
    jobs: state.jobs,
  };
};

const mapDispatchToProps = function () {
  return {
    setDisplayScreen: (screen) => setDisplayScreen(screen),
    uploadResume: (documentId, file, navigate) =>
      uploadResume(documentId, file, navigate),
    setErrorStatus: (error) => setErrorStatus(error),
    updateOpting: (optingStatus) => updateOpting(optingStatus),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
